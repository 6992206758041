
  import { Component, Prop } from 'vue-property-decorator'
  import { Negotiation } from '@/entities/purchase'
  import { fixPrice } from '@genio/automotive-console/src/utils/general'
  import { AppraisalView } from '@/components/forms/view/AppraisalView'

@Component({
  methods: { fixPrice },
})
  export default class NegotiationExecutive extends AppraisalView {
  @Prop({ type: [String, Number, Object, Array] }) value!: any

  // Methods

  // Getters
  get negotiation (): Negotiation {
    const { value } = this
    return value as Negotiation
  }

  get negotiationAuthorizedConsignment () {
    const { negotiationResponseAmountConsignment } = this.negotiation

    return negotiationResponseAmountConsignment.value
  }

  get offerPostInspection () {
    const { offerPostInspection } = this.negotiation
    return offerPostInspection
  }

  get publicationAmount () {
    const { negotiation } = this
    return negotiation?.inspection?.appraisal?.expectedPublicationAmount
  }

  get lastAppraisalResponse () {
    const { negotiation } = this
    return negotiation?.inspection.appraisal.lastAppraisalResponse
  }

  get lastAppraisalConsignmentResponse () {
    const { negotiation } = this
    return negotiation?.inspection.appraisal.lastAppraisalConsignmentResponse
  }

  get inspectionDiscount () {
    const { negotiation } = this
    return negotiation?.inspectionAmount?.value
  }

  get discountInspectionConsignment () {
    const { inspectionDiscount, lastAppraisalConsignmentResponse } = this

    return lastAppraisalConsignmentResponse.value - inspectionDiscount
  }

  get discountInspectionPurchase () {
    const { inspectionDiscount, lastAppraisalResponse } = this

    return lastAppraisalResponse.value - inspectionDiscount
  }

  get negotiationAuthorizedPurchase () {
    const { negotiation } = this
    return negotiation?.negotiationResponseAmount.value
  }

  get negotiationResponseAppealed () {
    const { negotiation } = this
    return negotiation?.negotiationResponseAppealed
  }

  get differencePurchase () {
    const { negotiationAuthorizedPurchase, negotiationResponseAppealed } = this

    if (!negotiationAuthorizedPurchase || !negotiationResponseAppealed?.value) {
      return 0
    }

    return (negotiationResponseAppealed?.value || 0) - negotiationAuthorizedPurchase
  }

  get differenceConsignment () {
    const { negotiationAuthorizedConsignment, negotiationResponseAppealedConsignment } = this

    if (!negotiationAuthorizedConsignment || !negotiationResponseAppealedConsignment?.value) {
      return 0
    }

    return (negotiationResponseAppealedConsignment?.value || 0) - negotiationAuthorizedConsignment
  }

  get negotiationResponseAppealedConsignment () {
    const { negotiation } = this

    return negotiation?.negotiationResponseAppealedConsignment
  }

  get agreedAmountAppraisal () {
    const { negotiation } = this

    return negotiation?.inspection?.appraisal?.agreedAmount
  }

  get balanceExecutive () {
    const { agreedAmountAppraisal, lastAppraisalConsignmentResponse, lastAppraisalResponse } = this

    if (agreedAmountAppraisal > lastAppraisalResponse.value) {
      return lastAppraisalConsignmentResponse.value - agreedAmountAppraisal
    }

    return lastAppraisalResponse.value - agreedAmountAppraisal
  }

  get balanceExecutiveInspection () {
    const { balanceExecutive, inspectionDiscount } = this

    const amount = balanceExecutive - inspectionDiscount
    return amount < 0 ? 0 : amount
  }

  get closePrice () {
    const { negotiation, balanceExecutiveInspection } = this

    if (!negotiation?.agreedAmount) {
      return null
    }

    return negotiation.agreedAmount - balanceExecutiveInspection
  }

  get balanceNegotiation () {
    const { closePrice, negotiationAuthorizedConsignment, negotiationAuthorizedPurchase } = this
    if (!closePrice) {
      return 0
    }
    if (closePrice > negotiationAuthorizedPurchase) {
      return negotiationAuthorizedConsignment - closePrice
    }
    return negotiationAuthorizedPurchase - closePrice
  }

  get displayPurchase () {
    const { maxMileage, negotiation } = this

    return this.displayPurchaseFields(negotiation?.inspection?.appraisal?.kms, maxMileage)
  }
  }
